<template>
  <div>
    <div style="padding: 10px">
      <el-button
        @click="addSeriesGoods"
        type="primary"
        v-show="showAddDiv == false"
        size="mini"
        >添加商品</el-button
      >
      <el-button @click="clearGoods" type="danger" size="mini"
        >清空商品</el-button
      >
    </div>

    <div style="padding: 10px" v-show="showAddDiv == true">
      <div>
        <textarea
          :placeholder="showAddCodeView"
          v-model="addGoodsList"
          class="addTextarea"
          spellcheck="false"
        ></textarea>
      </div>
      <el-button @click="submitSeriesGoods" type="primary" size="mini"
        >提交</el-button
      >
      <el-button @click="showAddDiv = false" type="infor" size="mini"
        >关闭</el-button
      >
    </div>

    <div class="seriesGoodsList" style="padding: 10px">
      <el-table :data="tableData" height="600" border style="width: 100%">
        <el-table-column prop="item_no" label="商品编码" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="infor" @click="delRow(scope.row)" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gsid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      addGoodsList: "",
      showAddCodeView: "",
      showAddDiv: false,
      tableData: [],
    };
  },
  watch: {
    gsid() {
      this.getData();
      this.addGoodsList = "";
      this.showAddDiv = false;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    //清空商品
    clearGoods() {
      let gsid = parseInt(this.gsid);
      if (gsid > 0) {
        this.$axios
          .delete(`/a1/series/clear/goods/${gsid}`)
          .then((res) => {
            this.$message.success("商品清空");
            this.getData();
          })
          .catch((resErr) => {
            this.$message.error("删除失败");
          });
      }
    },
    //删除商品
    delRow(row) {
      let id = row.id;
      this.$axios
        .delete(`/a1/series/delGoods/${id}`)
        .then((res) => {
          this.$message.success("删除成功");
          this.getData();
        })
        .catch((resErr) => {
          this.$message.error("删除失败");
        });
    },
    //提交商品数据到活动列表
    submitSeriesGoods() {
      let addGoodsStr = this.addGoodsList;
      let addArr = addGoodsStr.split("\n");

      let postGoodsItems = [];
      for (let i = 0; i < addArr.length; i++) {
        if (addArr[i] != "") {
          postGoodsItems.push(addArr[i]);
        }
      }
      //验证提交
      let gsid = parseInt(this.gsid);
      if (postGoodsItems.length > 0 && gsid > 0) {
        let postData = {
          gs_id: gsid,
          items: postGoodsItems,
        };
        this.$axios
          .post(`/a1/series/goods`, postData)
          .then((res) => {
            this.$message.success("提交成功");
            this.getData();
          })
          .catch((resErr) => {
            this.$message.success("提交失败");
          });
      }
      console.log(postGoodsItems);
    },
    //显示添加商品
    addSeriesGoods() {
      this.showAddDiv = true;
      this.showAddCodeView = "商品编码\r\n商品编码";
    },
    getData() {
      let id = parseInt(this.gsid);
      this.$axios.get(`/a1/series/goods/all/${id}`).then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>

<style type="text/css" scoped>
.addTextarea {
  width: 100%;
  height: 150px;
  border: 1px solid #218da0;
  color: #b16139;
  font-size: 14px;
}
</style>
