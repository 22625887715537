<template>
	<div>
		<div style="padding-left: 10px; margin-top: 10px">
			<el-button @click="addRowShow()" type="danger" size="mini">添加</el-button>
		</div>

		<div class="seriesCenter" style="padding: 10px">
			<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
				@selection-change="handleSelectionChange" border>
				<el-table-column type="selection" width="55"> </el-table-column>

				<el-table-column prop="id" label="id" align="center" width="55"></el-table-column>

				<el-table-column prop="name" label="活动名称" align="left"></el-table-column>

				<el-table-column label="活动状态" width="220" align="center">
					<template v-slot="scope">
						<p v-if="scope.row.sta == 1">上线</p>
						<p v-if="scope.row.sta == 0">下线</p>
					</template>
				</el-table-column>

				<el-table-column prop="address" width="220" align="center" label="地址">
					<template slot-scope="scope">
						<el-button @click="editRowShow(scope.row)" type="danger" size="mini">编辑</el-button>
						<el-button @click="showSeriesListDiv(scope.row)" type="primary" size="mini">查看</el-button>
						<el-button @click="delRow(scope.row)" type="infor" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 活动管理 -->
		<el-dialog :title="seriesData.title" :visible.sync="showEditSeries" width="30%" :show-close="false"
			:before-close="handleClose">
			<div>
				<el-form ref="form" label-width="80px">
					<el-form-item label="名称(CN)">
						<el-input v-model="seriesData.form.name"></el-input>
					</el-form-item>

					<el-form-item label="名称(EN)">
						<el-input v-model="seriesData.form.name_en"></el-input>
					</el-form-item>

					<el-form-item label="活动状态">
						<el-switch v-model="seriesData.form.sta" active-text="上线" inactive-text="下线">
						</el-switch>
					</el-form-item>

					<el-form-item label="陈列照">
						<el-select v-model="seriesData.form.display_category" filterable placeholder="请选择">
							<el-option
							v-for="item in displaySelectList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>

					
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showEditSeries = false">取 消</el-button>
				<el-button type="primary" @click="submitSeries">提 交</el-button>
			</span>
		</el-dialog>

		<!-- IP活动商品列表 -->
		<el-drawer title="活动商品列表" :with-header="false" :visible.sync="seriesListDiv" size="40%" direction="rtl"
			:before-close="handleClose">
			<div class="editDivBase">
				<span class="editGoodsTitle">IP活动商品列表</span>
				<span class="editGoodsContent">
					<seriesList :gsid="lookGsId"></seriesList>
				</span>
				<span class="editGoodsfooter">
					<el-button type="danger" style="margin-left: 92%" @click="seriesListDiv = false">关闭</el-button>
				</span>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import seriesList from "@/views/admin/goods/series/seriesList.vue";

export default {
		components: {
			seriesList,
		},
		data() {
			return {
				lookGsId: 0,
				seriesListDiv: false,
				seriesData: {
					title: "添加活动",
					submit: "add",
					form: {
						id: 0,
						name: "",
						name_en:"",
						sta: true,
						display_category:"",
					},
				},
				showEditSeries: false, //管理活动
				tableData: [],
				multipleSelection: [],
				// 下拉选择陈列照
				displaySelectList:[],
			};
		},
		created() {
			this.getData();
			this.getDisplayData();
		},
		methods: {
			//显示列表活动
			showSeriesListDiv(row) {
				this.seriesListDiv = true;
				this.lookGsId = row.id;
			},
			//删除一行
			delRow(row) {
				this.$confirm("确认删除？")
					.then((_) => {
						//删除
						let id = row.id;
						this.$axios.delete(`/a1/series/${id}`).then((res) => {
							this.getData();
							this.$message.success("删除成功");
						});
					})
					.catch((_) => {});
			},
			//提交Ip活动
			submitSeries() {
				let submitType = this.seriesData.submit;
				let postData = {};
				postData.name = this.seriesData.form.name;
				postData.name_en = this.seriesData.form.name_en;
				postData.display_category = this.seriesData.form.display_category;
				if (this.seriesData.form.sta) {
					postData.sta = "1";
				} else {
					postData.sta = "0";
				}
				if (submitType == "add") {
					this.addSeries(postData);
				}
				if (submitType == "update") {
					this.putSeries(postData);
				}
			},
			//添加
			addSeries(psotData) {
				//添加IP活动
				this.$axios
					.post(`/a1/series`, psotData)
					.then((res) => {
						this.$message.success("添加成功");
						this.getData();
						this.showEditSeries = false;
					})
					.catch((resErr) => {
						this.$message.success("添加失败");
					});
			},
			//修改
			putSeries(putData) {
				if (this.seriesData.form.id > 0) {
					let formId = this.seriesData.form.id;
					//修改参数
					this.$axios
						.put(`/a1/series/${formId}`, putData)
						.then((res) => {
							this.$message.success("修改成功");
							this.getData();
							this.showEditSeries = false;
						})
						.catch((resErr) => {
							this.$message.success("修改失败");
						});
				}
			},
			addRowShow() {
				this.showEditSeries = true;
				this.seriesData.title = "编辑活动";
				this.seriesData.submit = "add";
				this.seriesData.form.id = 0;
				this.seriesData.form.name = "";
				this.seriesData.form.sta = true;
				this.seriesData.form.display_category = ""
			},
			//显示编辑内容
			editRowShow(row) {
				this.showEditSeries = true;
				this.seriesData.title = "编辑活动";
				this.seriesData.submit = "update";
				this.seriesData.form.id = row.id;
				this.seriesData.form.name = row.name;
				this.seriesData.form.name_en = row.name_en;
				this.seriesData.form.display_category = row.displayCategory?row.displayCategory:""
				if (row.sta == 1) {
					this.seriesData.form.sta = true;
				} else {
					this.seriesData.form.sta = false;
				}
			},
			getData() {
				this.$axios.get("/a1/series").then((res) => {
					this.tableData = res.data.data;
				});
			},

			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//关闭编辑
			handleClose(done) {
				return false;
				// this.$confirm('确认关闭？')
				// .then(_ => {
				// 	done();
				// })
				// .catch(_ => {});
			},

			// 获取陈列照
			getDisplayData() {
				this.$axios.get("/a1/series/allDisplayCategory").then((res) => {
					this.displaySelectList = res.data.data;
				});
			},
		},
	};
</script>

<style>
	.editDivBase {
		height: 100vh;
	}

	.editDivBase {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.editGoodsContent {
		flex: 1;
		overflow: auto;
		overflow-y: scroll;
	}

	.editGoodsTitle {
		height: 5%;
		line-height: 50px;
		text-indent: 1em;
		background: #218da0;
		color: white;
	}

	.editGoodsfooter {
		height: 45px;
		line-height: 45px;
		border-top: 1px solid #c0ccda;
	}
</style>
